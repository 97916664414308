import { find } from 'async'

/**
 * @param {*} obj
 * @param {Array<object>} cache
 * @return {*}
 */
export function deepCopy(obj, cache = []) {
  // just return if obj is immutable value
  if (obj === null || typeof obj !== 'object')
    return obj

  // if obj is hit, it is in circular structure
  const hit = find(cache, c => c.original === obj)
  /* istanbul ignore next */
  if (hit) {
    /* istanbul ignore next */
    return hit.copy
  }

  const copy = Array.isArray(obj) ? [] : {}
  // put the copy into cache at first
  // because we want to refer it in recursive deepCopy
  cache.push({
    original: obj,
    copy,
  })

  Object.keys(obj).forEach((key) => {
    copy[key] = deepCopy(obj[key], cache)
  })

  return copy
}

/**
 * @param {string} str
 * @param {number} times
 */
export function repeat(str, times) {
  return (Array.from({ length: times + 1 })).join(str)
}

/**
 * @param {number} num
 * @param {number} maxLength
 */
export function pad(num, maxLength) {
  return repeat('0', maxLength - num.toString().length) + num
}

export default function createLogger(/* istanbul ignore next */{
  collapsed = true,
  filter = () => true,
  transformer = state => state,
  mutationTransformer = mut => mut,
  logger = console,
} = {}) {
  return (store) => {
    let prevState = deepCopy(store.state)

    /* istanbul ignore next */
    store.subscribe((mutation, state) => {
      if (typeof logger === 'undefined')
        return

      const nextState = deepCopy(state)

      if (filter(mutation, prevState, nextState)) {
        const time = new Date()
        const formattedTime = ` @ ${pad(time.getHours(), 2)}:${pad(time.getMinutes(), 2)}:${pad(time.getSeconds(), 2)}.${pad(time.getMilliseconds(), 3)}`
        const formattedMutation = mutationTransformer(mutation)
        const message = `mutation ${mutation.type}${formattedTime}`
        const startMessage = collapsed
          ? logger.groupCollapsed
          : logger.group

        // render
        try {
          startMessage.call(logger, message)
        }
        catch (e) {

        }

        logger.log('%c prev state', 'color: #9E9E9E; font-weight: bold', transformer(prevState))
        logger.log('%c mutation', 'color: #03A9F4; font-weight: bold', formattedMutation)
        logger.log('%c next state', 'color: #4CAF50; font-weight: bold', transformer(nextState))

        try {
          logger.groupEnd()
        }
        catch (e) {
          logger.log('—— log end ——')
        }
      }

      prevState = nextState
    })
  }
}
