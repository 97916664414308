<script>
// External Dependencies
import VueExtendLayouts from 'vue-extend-layout'

export default {
  name: 'App',
  components: {
    VueExtendLayouts,
  },
}
</script>

<template lang="pug">
  #app
    VueExtendLayouts(path="Layouts")
</template>

<style lang="scss">
    @import "@/assets/scss/global.scss";
</style>
