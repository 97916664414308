function initialState() {
  return {
    device: {
      isMobile: false,
      screenWidth: window.innerWidth,
    },
  }
}

const getters = {
  getIsMobile(state) {
    return state.device.isMobile
  },
  getScreenWidth(state) {
    return state.device.screenWidth
  },
}

const mutations = {
  updateDeviceInfos(state) {
    state.device.screenWidth = window.innerWidth
    state.device.isMobile = (window.innerWidth <= 1200)
  },
  resetStore(state) {
    const s = initialState()
    Object.keys(s).forEach((key) => {
      state[key] = s[key]
    })
  },
}

const state = initialState()

export default {
  getters,
  mutations,
  namespaced: true,
  state,
}
