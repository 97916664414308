// External Dependencies
import Vue from 'vue'
import Router from 'vue-router'

// Vue Router
Vue.use(Router)

// Variables
const defaultPageTitle = 'MeinAuto.de Loyalty' // Default page title

const router = new Router({
  mode: 'history', // get rid of hash based routes and switch to history mode
  routes: [
    {
      path: '/cashback/:id',
      name: 'RCashback',
      component: () => import(/* webpackChunkName: "RCashback" */'./views/cashback/Cashback.vue'),
      meta: {
        layout: 'default/Default',
        title: 'MeinAuto.de Loyalitätsprogramm',
        enableFluidLayout: true,
      },
      props: true,
    },
    {
      path: '*',
      name: 'RError',
      component: () => import(/* webpackChunkName: "RError" */ './views/error/Error.vue'),
      meta: {
        layout: 'default/Default',
        title: 'MeinAuto.de Neuwagen: Top-Preise & Rabatte beim Autokauf - MeinAuto.de',
        enableFluidLayout: true,
      },
      props: true,
    },
  ],
})

router.afterEach((to) => {
  Vue.nextTick(() => {
    document.title = to.meta.title || defaultPageTitle
  })
})

export function createRouter() {
  return router
}
