// datadogRum
import { datadogRum } from '@datadog/browser-rum'

/**
 * creates random alphanumeric string
 * @return {string}
 */
const createRandomString = () => Math.random().toString(36).substr(2, 5)

export const errorLogger = {
  data: {
    products: {
      cashback: 'CASHBACK',
    },
    apps: {
      retentionApp: 'RA',
    },
    services: {
      retention: 'RS',
    },
  },
  /**
   * log error in data-dog
   * @param {string} product
   * @param {string} project
   * @param {string | object} errorBody
   * @param {boolean} returnErrorCode
   * @param {string} errorMessage
   * @return {string | boolean}
   */
  logError(product, project, errorBody = null, returnErrorCode = false, errorMessage = '') {
    const errorCode = `${product}_${project}_${createRandomString().toUpperCase()}`
    datadogRum.addError(errorMessage || errorCode, {
      errorBody,
    })
    return returnErrorCode ? errorCode : true
  },
}
