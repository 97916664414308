// External dependencies
import axios from 'axios'

// Mixins
import { errorLogger } from '@/mixins/errorLogger.mixin.js'

// Config
import Configuration from '@/config/config'

// Define initial state setup
function initialState() {
  return {
    cashbackOffer: null,
    errorCode: '',
    isLoading: false,
    status: null,
  }
}

// define states
const state = initialState()

// define getters
const getters = {

  getCashbackOffer(state) {
    return state.cashbackOffer
  },

  getStatus(state) {
    return state.status
  },

  getErrorCode(state) {
    return state.errorCode
  },
}

// define mutations
const mutations = {

  setCashbackOffer(state, { data, callback }) {
    state.cashbackOffer = data
    if (callback)
      callback(true)
  },

  setStatus(state, data) {
    state.status = data
  },

  setErrorCode(state, data) {
    state.errorCode = data
  },
}

const actions = {
  async fetchCashbackOffer({ commit }, { id }) {
    return new Promise((resolve) => {
      // Define endpoint
      const endpointUrl = `${Configuration.value('VUE_APP_GATEWAY_HOST')}${Configuration.CONFIG.VUE_APP_ENDPOINTS.retention}/${id}`

      window.EventBus.$emit('showMaLoader', true)
      axios.get(endpointUrl)
        .then(res => commit('setCashbackOffer', { data: res.data, callback: resolve }))
        .catch((e) => {
          commit('setStatus', e.response.status)
          commit('setErrorCode', errorLogger.logError(
            errorLogger.data.products.cashback,
            errorLogger.data.apps.retentionApp,
            {
              Status: JSON.stringify(e.response?.status),
              Data: JSON.stringify(e.response?.data),
            },
            true,
          ))
          return commit('setCashbackOffer', { data: null, callback: resolve })
        }).finally(() => {
          window.EventBus.$emit('showMaLoader', false)
        })
    })
  },

  async updateCashbackOffer({ commit }, { id }) {
    return new Promise((resolve) => {
      // Define endpoint
      const endpointUrl = `${Configuration.value('VUE_APP_GATEWAY_HOST')}${Configuration.CONFIG.VUE_APP_ENDPOINTS.retention}/confirm/${id}`

      window.EventBus.$emit('showMaLoader', true)
      axios({
        method: 'post',
        url: endpointUrl,
      })
        .then(res => commit('setCashbackOffer', { data: res.data, callback: resolve }))
        .catch((e) => {
          commit('setStatus', e.response.status)
          commit('setErrorCode', errorLogger.logError(
            errorLogger.data.products.cashback,
            errorLogger.data.apps.retentionApp,
            {
              Status: JSON.stringify(e.response?.status),
              Data: JSON.stringify(e.response?.data),
            },
            true,
          ))
          return resolve(e.response.data)
        }).finally(() => {
          window.EventBus.$emit('showMaLoader', false)
        })
    })
  },
}

export default {
  actions,
  getters,
  mutations,
  namespaced: true,
  state,
}
