// External dependencies
import Vue from 'vue'

// Config
import Vuelidate from 'vuelidate'

// CL
import { GoogleTracking } from '@meinauto-gmbh/services'

/* datadogRum */
import { datadogRum } from '@datadog/browser-rum'
import packageJson from '../package.json'

// Internal dependencies
import App from './App.vue'
import store from './store'
import { createRouter } from './router'
import Configuration from '@/config/config'

// Vuelidate
Vue.use(Vuelidate)

// Prototype variables
Vue.config.productionTip = false
Vue.prototype.$tracking = new GoogleTracking()

// datadogRum variables
const environment = Configuration.value('VUE_APP_ENVIRONMENT')
const packageVersion = `${packageJson.name}@${packageJson.version}`

// ------------------------------------------------------------
// datadogRum config
datadogRum.init({
  applicationId: '97d06eff-5591-47a6-9dc8-1690e367a145',
  clientToken: 'pubea2de0e63b6a59cafbec188c5e6cce1c',
  defaultPrivacyLevel: 'mask-user-input',
  env: environment,
  service: 'retention-app',
  sessionReplaySampleRate: 0,
  sessionSampleRate: 100,
  site: 'datadoghq.eu',
  trackLongTasks: true,
  trackResources: true,
  trackUserInteractions: true,
  version: packageVersion,
})

export function createApp() {
  const router = createRouter()

  // Init EventBus for local App
  window.EventBus = new Vue()

  const app = new Vue({
    router,
    store,
    render: h => h(App),
  }).$mount('#app')
  return { app, store, router }
}

createApp()
