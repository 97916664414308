import Vue from 'vue'
import Vuex from 'vuex'
import createPersistedState from 'vuex-persistedstate'
import createLogger from './logger'

// import namespaced store modules
import storeModules from './modules'

Vue.use(Vuex)
const debug = process.env.NODE_ENV !== 'production'

let persistedState
// eslint-disable-next-line import/no-mutable-exports
let store = null
if (navigator.cookieEnabled) {
  // do not persist RemoteSale information
  persistedState = createPersistedState({
    paths: storeModules,
  })

  store = new Vuex.Store({
    modules: storeModules,
    strict: debug,

    plugins: persistedState
      ? /* istanbul ignore next */debug ? [persistedState, createLogger()] : [persistedState]
      : /* istanbul ignore next */debug && [createLogger()],
  })
}

/**
 * @returns {Store<any>}
 */
export default store
